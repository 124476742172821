import { Layout, Space, Input, Button, Table, Typography } from "antd";
import { useState } from "react";
import useRegisterData from "../../hooks/useRegisterData";
import { LEVEL_MAP, QUESTIONS_MAP } from "../../constants/common";

const answer = "kidpitchingeffectlove";

const columns = [
  { title: "전직", dataIndex: "before", key: "before" },
  { title: "현직", dataIndex: "current", key: "current" },
];

const LayoutStyle = { width: "100%", height: "100vh" };

const Register = () => {
  const [isLogined, setIsLogined] = useState(false);
  const [password, setPassword] = useState("");
  const { data } = useRegisterData(isLogined);

  const handleLogin = () => {
    if (answer === password) {
      setIsLogined(true);
    } else {
      setPassword("");
    }
  };

  if (!isLogined)
    return (
      <Layout style={LayoutStyle}>
        <Space.Compact style={{ padding: "68px 16px" }}>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            placeholder='비밀번호 입력'
          />
          <Button type='primary' onClick={handleLogin}>
            완료
          </Button>
        </Space.Compact>
      </Layout>
    );

  return (
    <Layout style={LayoutStyle}>
      <Typography.Title level={4}>신청자 {data.length}명</Typography.Title>
      <Table
        columns={columns}
        dataSource={data}
        rowKey='id'
        expandable={{
          expandedRowRender: (rec) => (
            <div>
              {LEVEL_MAP.map((item) => {
                if (item === null) return null;
                return (
                  <>
                    <Typography.Title level={5}>
                      {QUESTIONS_MAP[item]}
                    </Typography.Title>
                    {item === "link" ? (
                      <Typography.Link href={rec[item]} target='_blank'>
                        {rec[item]}
                      </Typography.Link>
                    ) : (
                      <Typography>{rec[item]}</Typography>
                    )}
                    <br />
                  </>
                );
              })}
            </div>
          ),
        }}
      />
    </Layout>
  );
};

export default Register;
