import styled from "styled-components";

const Notice = ({ children, text }) => (
  <Container>{text || children}</Container>
);

export default Notice;

const Container = styled.p`
  padding: 4px;
  color: white;
  background-color: #57575730;
  border-radius: 50px;
  font-size: 16px;
  display: block;
  width: fit-content;
  margin: 0 auto 4px;

  > a {
    all: unset;
    text-decoration: underline;
  }
`;
