import { useEffect, useState } from "react";

async function getData() {
  const response = await fetch(
    "https://65cd9221c715428e8b3e96aa.mockapi.io/register",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.json(); // JSON 응답을 네이티브 JavaScript 객체로 파싱
}

export default function useRegisterData(isLogin) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isLogin) {
      getData().then((data) => {
        setData(data);
        console.log(data);
      });
    }
  }, [isLogin]);

  return { data };
}
