import { Link } from "react-router-dom";
import styled from "styled-components";
import { ASSETS_URL } from "../../constants/common";

const Item = ({ to = "", img = "", title = "방제", desc = "desc", num }) => (
  <ItemWrapper>
    <Link to={to}>
      <RoomImg src={`${ASSETS_URL}${img}`} alt={to} />
      <div>
        <p>
          {title} <strong>{num}</strong>
        </p>
        <Desc>{desc}</Desc>
      </div>
    </Link>
  </ItemWrapper>
);

export default Item;

const ItemWrapper = styled.li`
  > a {
    all: unset;
    display: flex;
    padding: 16px 12px;
  }

  strong {
    color: grey;
  }

  p {
    font-size: 16px;
  }

  border-bottom: 1px solid lightgray;
`;

const RoomImg = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 8px;
  border-radius: 4px;
`;

const Desc = styled.p`
  color: grey;
  margin-top: 8px;
`;
