import { createBrowserRouter } from "react-router-dom";
import Chat from "../components/Chat";
import List from "../components/List";
import MustRead from "../components/Chat/MustRead";
import Register from "../components/Register";

const router = createBrowserRouter([
  { path: "/", element: <List /> },
  {
    path: "/chat",
    element: <Chat isReal />,
  },
  { path: "/practice", element: <Chat /> },
  { path: "/notice", element: <MustRead /> },
  { path: "/admin", element: <Register /> },
]);

export default router;
