export const ASSETS_URL = `${process.env.PUBLIC_URL}/assets`;

export const MEMBER = {
  aki: {
    name: "선발투수(24)/프본",
    img: "선발타자.jpg",
  },
  nsh: {
    name: "노시환상적으로날려줘요◑▽◐",
    img: "노시환.jpg",
  },
  cgm: {
    name: "답내친/영구결번내꺼[♥]",
    img: "최경민.png",
  },
  ahw: {
    name: "영구결번/답내친내꺼[♡]",
    img: "안해원.png",
  },
  hyw: {
    name: "『칠흑의 살인병기』 견제死",
    img: "견제사.jpg",
  },
  ljy: {
    name: "투수같지도않은I™",
    img: "재영.jpg",
  },
  csj: {
    name: "Diamond Ace G.A.S..♬",
    img: "구악새.jpg",
  },
  won: {
    name: "Yougyuksubao",
    img: "원.jpg",
  },
  gh: {
    name: "ㅋı스톤ºl EyEdRoPs ,",
    img: "키스톤.jpg",
  },
  gwc: {
    name: "번트/구원찬/씻지않기니스트",
    img: "구원투수.jpg",
  },
  sang: {
    name: "리얼파워고스트다크드래곤견제구",
    img: "견제구.png",
  },
  iet: {
    name: "❄️❄️잔루❄️❄️반동결❄️",
    img: "잔루.jpg",
  },
  kwj: {
    name: "박해민MN",
    img: "임찬규.jpg",
  },
  notice: {
    name: "KID PITCHING EFFECT",
    img: "notice.jpg",
  },
};

export const QUESTIONS = [
  [
    { from: "aki", msg: ["안녕하시오."] },
    { from: "iet", msg: ["ㅎㅇㅋㅋ"] },
    { from: "iet", msg: ["빵가루"] },
    { from: "kwj", msg: ["이딴찐따팸에 신청하러온게 맞음..?"] },
  ],
  [
    {
      from: "sang",
      msg: ["이쁜아"],
    },
    {
      from: "sang",
      msg: ["어디서 본것같은데"],
    },
    {
      from: "sang",
      msg: ["전직좀 알려주겠어?"],
    },
    {
      from: "sang",
      msg: ["없으면 팸 소속인 지인 두 명의 링크를 주련?"],
    },
  ],
  [
    {
      from: "sang",
      msg: ["이쁜이 전직도 이쁜이??ㅋ"],
    },
    {
      from: "sang",
      msg: ["그럼"],
    },
    {
      from: "sang",
      msg: ["현직은 머지?"],
    },
    {
      from: "sang",
      msg: ["현직 없으면 팸 소속 지인 한 명의 링크를 주겟니?"],
    },
    {
      from: "aki",
      msg: ["아 그 전직대신 지인링크주셧어도 추가로더주셔야댐"],
    },
  ],
  [
    {
      from: "aki",
      msg: ["얘야 몰르느숙녀에게 그렇게 말걸지맠ㅋㅋㅋㅋㅋ"],
    },
    {
      from: "sang",
      msg: ["ㅋㅋ?"],
    },
    {
      from: "ahw",
      msg: ["죄송해요"],
    },
    {
      from: "ahw",
      msg: ["아이들이 모자라서 그렇지 다들 착해요"],
    },
    {
      from: "ahw",
      msg: ["모집 글 전체 공개로 공유한 스토리 링크 주시면 돼요"],
    },
  ],
  [
    { from: "iet", msg: ["W.H.Y 왜 착한척???ㅋㅋㅋㅋ"] },
    { from: "cgm", msg: ["우 리마누라한테 함부 로말 하지마"] },
    { from: "hyw", msg: ["흠ㅋㅋㅋㅋ 마누라는 답내친같은뎈ㅋㅋㅋㅋ"] },
    { from: "cgm", msg: ["너지 금내가 오메가라는거야"] },
    {
      from: "ahw",
      msg: ["에휴"],
    },
    {
      from: "ahw",
      msg: ["다 조용히 하고"],
    },
    {
      from: "ahw",
      msg: ["각자 준비한 질문 하나씩 해"],
    },
    { from: "iet", msg: ["나부터 하도록 하지"] },
    { from: "iet", msg: ["너 하는 PC 게임 뭐 있어?"] },
  ],
  [
    { from: "gwc", msg: ["숙녀에게 반말은 실례인데"] },
    { from: "sang", msg: ["ㅇㅈ"] },
    { from: "sang", msg: ["젠틀하지 못해"] },
    {
      from: "won",
      msg: ["다들 이상한 말을 너무 많이 해서"],
    },
    {
      from: "won",
      msg: ["나까지 이상해지는 기분..."],
    },
    {
      from: "won",
      msg: ["좋아하는 한드 뭐예요?"],
    },
  ],
  [
    { from: "aki", msg: ["시간지체하지말고 스겜"] },
    { from: "aki", msg: ["노시환ㄱㄱ"] },
    { from: "nsh", msg: ["요즘 제일 많이 듣는 노래 머임?"] },
  ],
  [
    { from: "aki", msg: ["강희야너차례란다"] },
    { from: "gh", msg: ["취미가 뭐야?"] },
  ],
  [
    { from: "kwj", msg: ["ㅈㄴ 뻔한거물어보네"] },
    { from: "kwj", msg: ["다음 누구임"] },
    { from: "hyw", msg: ["내가 하지"] },
    { from: "hyw", msg: ["카스 친구 몇 명임??"] },
  ],
  [
    { from: "nsh", msg: ["찐따 같은 질문을 하네"] },
    { from: "hyw", msg: ["ㅋㅋ?"] },
    { from: "csj", msg: ["중요한 질문이긴 해"] },
    { from: "ljy", msg: ["맞음"] },
    { from: "ahw", msg: ["경민아 니가 물어봐 그냥"] },
    { from: "ahw", msg: ["얘네 계속 떠들 듯"] },
    {
      from: "cgm",
      msg: ["알았 어요여보.."],
    },
    {
      from: "cgm",
      msg: ["팔만대장경 다 읽기 vs 대장 내시경 팔만 번 하기"],
    },
  ],
  [
    {
      from: "sang",
      msg: ["난 ㄸㄲ가 없어서"],
    },
    {
      from: "sang",
      msg: ["팔만대장경을 다 읽을 수밖에 없어"],
    },
    {
      from: "kwj",
      msg: ["헉..////"],
    },
    {
      from: "kwj",
      msg: ["너 씹탑이야..??"],
    },
    {
      from: "sang",
      msg: ["어 ㅋㅋ"],
    },
    {
      from: "sang",
      msg: ["그냥 사귈래?ㅅㅂ"],
    },
    {
      from: "kwj",
      msg: ["어그럴까"],
    },
    {
      from: "iet",
      msg: ["WOW ㅋㅋㅋㅋㅋㅋ"],
    },
    {
      from: "ahw",
      msg: ["ㅅㅂ 그만해"],
    },
    {
      from: "ahw",
      msg: ["제가 물어볼게요"],
    },
    {
      from: "ahw",
      msg: ["환연 봄?"],
    },
  ],
  [
    {
      from: "aki",
      msg: ["반존대 달콤ㅋㅋ"],
    },
    {
      from: "aki",
      msg: ["저의 질문은요"],
    },
    {
      from: "aki",
      msg: ["로아 하는 중이야? 할 생각 있지? ㅋㅋ"],
    },
  ],
  [
    {
      from: "ahw",
      msg: ["아키야 웬일로 영양가 있는 질문을 했냐"],
    },
    {
      from: "ahw",
      msg: ["갑자기 고능해 보임"],
    },
    {
      from: "aki",
      msg: ["얘야나느원래고능햌ㅋㅋㅋㅋㅋㅋㅋㅋㅋ"],
    },
    {
      from: "aki",
      msg: ["다음질문은 나의ex gf가하자"],
    },
    {
      from: "aki",
      msg: ["오해하지마 6년전의 일이고우리는 친구가됫으니.."],
    },
    { from: "csj", msg: ["최악의 구 멘트 모임"] },
  ],
  [
    { from: "aki", msg: ["..?"] },
    { from: "aki", msg: ["넌뭐엿는데?시발"] },
    { from: "aki", msg: ["얘야"] },
    { from: "hyw", msg: ["노렷넼ㅋㅋㅋㅋㅋㅋ"] },
    { from: "csj", msg: ["하하 ㅋㅋㅋ"] },
    {
      from: "kwj",
      msg: ["잘해주지그랫엌ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ"],
    },
    {
      from: "kwj",
      msg: ["이제내가함"],
    },
    {
      from: "kwj",
      msg: [
        "이상형 만나고 평생 친구 잃기 vs 버러지 만나고 평생 왜 만났냐는 소리 듣기",
      ],
    },
  ],
  [
    {
      from: "won",
      msg: ["그래도 버러지가 낫지 않나?"],
    },
    {
      from: "won",
      msg: ["친구는 평생 남잖아.."],
    },
    { from: "aki", msg: ["잃을친구가 없음녀되"] },
    { from: "aki", msg: ["영원이처럼"] },
    { from: "hyw", msg: ["ㅋㅋ?"] },
    {
      from: "sang",
      msg: ["그래서 나는 모두 내 여자로 만들었지"],
    },
    { from: "cgm", msg: ["역시알파메잉느 다르구나.."] },
    { from: "ahw", msg: ["또 시작이네 또"] },
    { from: "ahw", msg: ["질문 안 한 사람 누구야"] },
    { from: "ljy", msg: ["나"] },
    { from: "ljy", msg: ["좋아하는 야구 응원가 ㅁㅇ?"] },
  ],
  [
    { msg: ["님은 뭐예요?"] },
    { from: "csj", msg: ["아니절대그걸물어봐선안되느데"] },
    { from: "ljy", msg: ["케세라세라이글스의최재훈워어어어케세라세라"] },
    {
      from: "cgm",
      msg: ["뭘좀 아네 ㅋㅋ"],
    },
    {
      from: "cgm",
      msg: ["케세라ㅏ세라"],
    },
    {
      from: "cgm",
      msg: ["이글스의쵀재훈워어어어케세라세라"],
    },
    {
      from: "ljy",
      msg: ["내기준 애국가임#"],
    },
    {
      from: "ljy",
      msg: ["진짜롴ㅋㅋ"],
    },
    {
      from: "ljy",
      msg: ["들으면멍..해지고찡..해짐"],
    },
    {
      from: "cgm",
      msg: ["한화의이진영워어어어 한화의이진영워어어"],
    },
    {
      from: "cgm",
      msg: ["이순간너의 모든것를보여 줘"],
    },
    {
      from: "cgm",
      msg: ["넌이진 영이다"],
    },
    { from: "ahw", msg: ["안 한 사람 있으면 빨리 해"] },
    {
      from: "gwc",
      msg: ["나는 마지막을 기다렸어"],
    },
    {
      from: "gwc",
      msg: ["이제 내 차례야"],
    },
    {
      from: "gwc",
      msg: ["아홉 글자로 아무 말이나 해 봐 (이거됨?)"],
    },
    { from: "kwj", msg: ["웅"] },
    { from: "gwc", msg: ["아홉 글자로 아무 말이나 해 봐"] },
  ],
  [
    { from: "aki", msg: ["그럼이제 개인질문은끝나거임?"] },
    { from: "ahw", msg: ["한 명 안 했는데"] },
    { from: "aki", msg: ["누가안함?"] },
    { from: "aki", msg: ["김잔루인가?"] },
    {
      from: "iet",
      msg: ["나는 일등 으로했어 ㅋ"],
    },
    {
      from: "iet",
      msg: ["무슨 게임을 하는지 물어봤지???"],
    },
    {
      from: "iet",
      msg: ["난 게임 좋아하는사람이 좋걸랑"],
    },
    { from: "kwj", msg: ["그럼 ㄴㄱ안함"] },
    { from: "sang", msg: ["사실 나다"] },
    { from: "aki", msg: ["ㅅㅂ"] },
    { from: "aki", msg: ["얘야"] },
    {
      from: "sang",
      msg: ["처음 보는 여성에게"],
    },
    {
      from: "sang",
      msg: ["강한 임팩트를 남기려면"],
    },
    {
      from: "sang",
      msg: ["숫자를 세고 있어야 해"],
    },
    {
      from: "sang",
      msg: ["물어보도록 하지"],
    },
    {
      from: "sang",
      msg: ["헬스 함?"],
    },
  ],
  [
    { from: "aki", msg: ["ㅈ같은질문"] },
    { from: "ahw", msg: ["야 그럼 빨리 공통 질문이나 드려"] },
    { from: "aki", msg: ["부팸장아 이럴때일해야되.."] },
    { from: "kwj", msg: ["앙"] },
    { from: "kwj", msg: ["근데머엿지??"] },
    { from: "sang", msg: ["아방하고"] },
    { from: "sang", msg: ["귀엽네? ㅋㅋ"] },
    {
      from: "aki",
      msg: ["ㅋㅋ?"],
    },
    {
      from: "aki",
      msg: ["저희 며칠 전에 올린 입장 영상 보셨나요?"],
    },
    {
      from: "aki",
      msg: ["거기에 대한 당신의 생각을 이유와 함께 말해 주시죠??"],
    },
    {
      from: "aki",
      msg: ["님의 의견이 어떻든 아무 불이익이 없습니다???"],
    },
  ],
  [
    { from: "won", msg: ["오.. 흥미롭군"] },
    { from: "aki", msg: ["근데 키스톤아 넌왜말을안하니?"] },
    { from: "iet", msg: ["과묵 한 컨샙??"] },
    { from: "gh", msg: ["개뻔한 질문이라길래"] },
    { from: "gh", msg: ["눈물을 좀 훔쳤지"] },
    { from: "hyw", msg: ["ㅉㅉㅋㅋㅋㅋㅋㅋ 맘이여리넼ㅋㅋㅋㅋ"] },
    {
      from: "sang",
      msg: ["내가 물어보아도 될까?"],
    },
    {
      from: "sang",
      msg: ["마지막으로 궁금한 게 있는데"],
    },
    { from: "kwj", msg: ["어그래"] },
    {
      from: "sang",
      msg: ["마지막으로 하고 싶은 말이 있는가 여자?"],
    },
    {
      from: "sang",
      msg: ["과묵해도 좋고"],
    },
    {
      from: "sang",
      msg: ["말이 많아도 좋고"],
    },
    {
      from: "sang",
      msg: ["난 너라면 다 좋네? 어쩌지?"],
    },
  ],
  [
    {
      from: "aki",
      msg: ["얘야 이제 그맞붙잡아 내보내드려야지"],
    },
    {
      from: "aki",
      msg: ["안녕히가세요"],
    },
    {
      from: "aki",
      msg: ["조만간봽죠?"],
    },
  ],
];

export const LEVEL_MAP = [
  null,
  "before",
  "current",
  "link",
  "iet",
  "won",
  "nsh",
  "gh",
  "hyw",
  "cgm",
  "ahw",
  "aki",
  "csj",
  "kwj",
  "ljy",
  "gwc",
  "sang",
  "common",
  "additional",
];

export const QUESTIONS_MAP = {
  before: "전직 혹은 지인 두 명의 링크",
  current: "현직 혹은 지인 한 명의 링크",
  link: "카카오스토리로 공유한 링크",
  iet: "좋아하는 PC 게임",
  won: "좋아하는 한국 드라마",
  nsh: "요즘 자주 듣는 노래",
  gh: "취미",
  hyw: "카카오스토리 친구 몇 명?",
  cgm: "팔만대장경 다 읽기 vs 대장 내시경 팔만 번 하기",
  ahw: "환연 보는지?",
  aki: "로스트아크 하거나 할 생각 있는지",
  csj: "최악의 구 멘트",
  kwj: "이상형 만나고 평생 친구 잃기 vs 버러지 만나고 평생 왜 만났냐는 소리 듣기",
  ljy: "좋아하는 응원가",
  gwc: "아홉 글자로 아무 말",
  sang: "헬스 함?",
  common: "근친에 대한 생각",
  additional: "기타 하고 싶은 말",
};

export const NOTICE = [
  ["임관은 왜 안 물어보시나요?"],
  ["임관 제도 없습니다."],
  ["수식어는요?"],
  ["합격 후 선점 가능합니다."],
  ["모집은 언제 마감하나요?"],
  ["2O일 자정 이후에 예고 없이 마감합니다."],
  ["뽑는 기준은 무엇인가요?"],
  ["답변 기반으로 저희와 잘 맞을 것 같은 분을 뽑을 예정입니다."],
  ["제출 후 수정 가능한가요?"],
  [
    "원칙적으로 불가능합니다. 마련된 연습방에서 진행 방식 확인 뒤 신청해 주세요.",
  ],
  ["꼭 수정해야 할 이유가 있습니다...."],
  ["불가피한 사유가 있을 경우 선발 투수에게 문의해 주세요."],
];
