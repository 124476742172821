import styled from "styled-components";
import { ASSETS_URL, MEMBER } from "../../constants/common";

const ChatBox = ({ from, msg, idx, subIdx, section, msgLevel, chatLevel }) => {
  return (
    <Layout isVisible={section > idx || chatLevel >= subIdx} isSent={!from}>
      {from && (
        <ProfileWrapper>
          <Profile src={`${ASSETS_URL}/${MEMBER[from]?.img}`} alt={from} />
        </ProfileWrapper>
      )}
      <ChatWrapper isSent={!from}>
        {from && <Nickname>{MEMBER[from]?.name}</Nickname>}
        {from ? (
          msg?.map((item, msgIdx) => {
            const isVisible =
              section > idx || chatLevel > subIdx || msgLevel >= msgIdx;

            return (
              <Received isVisible={isVisible} key={item}>
                {item}
              </Received>
            );
          })
        ) : (
          <Sent>{msg}</Sent>
        )}
      </ChatWrapper>
    </Layout>
  );
};

export default ChatBox;

const Layout = styled.section`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  align-items: start;
  padding: 8px 12px;
  gap: 8px;
  justify-content: ${(props) => (props.isSent ? "right" : "left")};
`;

const ChatWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isSent ? "end" : "start")};
`;

const ChatText = styled.p`
  padding: 5px 8px;
  width: fit-content;
  margin: 2px 0;
  border-radius: 50px;
  font-size: 16px;
`;

const Nickname = styled.p`
  width: fit-content;
  margin: 0 0 4px;
  font-size: 16px;
`;

const Received = styled(ChatText)`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  background-color: white;
`;

const Sent = styled(ChatText)`
  background-color: yellow;
`;

const ProfileWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
`;

const Profile = styled.img`
  object-fit: contain;
  width: 100%;
`;
