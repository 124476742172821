import styled from "styled-components";
import Header from "./Header";
import ChatBox from "./ChatBox";
import { NOTICE } from "../../constants/common";
import Notice from "./Notice";

const MustRead = () => {
  return (
    <Layout>
      <Header title='신청 전 필독' />
      <Notice text='공지를 숙지하지 않아 발생하는 불이익은' />
      <Notice text='제가 책임질 자신이 없습니다....' />
      {NOTICE.map((item, idx) => (
        <ChatBox
          key={item}
          msg={item}
          from={idx % 2 === 1 ? "notice" : undefined}
          section={100}
          idx={0}
        />
      ))}
      <Notice>
        기타 문의는{" "}
        <a
          href='http://story.kakao.com/tjsqkfpitcher'
          target='_blank'
          rel='noopener noreferrer'
        >
          선발 투수
        </a>
        로 부탁드립니다.
      </Notice>
    </Layout>
  );
};

export default MustRead;

const Layout = styled.main`
  padding-top: 72px;
  padding-bottom: 60px;
`;
