import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Dosi;
    src: url('${process.env.PUBLIC_URL}/assets/dosifont.ttf');
  }

  body, p {
    margin: 0;
    font-family: Dosi;
  }

  button {
    all: unset;
  }
`;

export default GlobalStyle;

export const Layout = styled.article`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #c1d7df;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const Header = styled.header`
  padding: 16px 12px;
  background-color: #302323;
  position: fixed;
  width: calc(100% - 24px);
  max-width: 476px;
  top: 0;
  left: 50%;
  font-size: 16px;
  transform: translateX(-50%);
  z-index: 3;
  color: white;

  > strong {
    color: #fffc4f;
  }
`;
