import { useState } from "react";
import styled from "styled-components";

const Keyboard = ({ isDisabled, onSubmit }) => {
  const [input, setInput] = useState("");

  const handleSubmit = () => {
    onSubmit(input);
    setInput("");
  };

  return (
    <InputWrapper>
      <Input
        placeholder='메시지를 입력하세요'
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={isDisabled}
      />
      <SendButton onClick={handleSubmit} disabled={isDisabled}>
        전송♡
      </SendButton>
    </InputWrapper>
  );
};

export default Keyboard;

const InputWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: calc(100% - 24px);
  max-width: 476px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
`;

const Input = styled.textarea`
  all: unset;
  flex: 1;
  font-size: 16px;
`;

const SendButton = styled.button`
  background-color: #fffc4f;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;

  &:disabled {
    background-color: #e2e2e2;
  }
`;
