import styled from "styled-components";
import { Header } from "../../style";
import Item from "./Item";

const List = () => (
  <Layout>
    <Header>채팅</Header>
    <ListWrapper>
      <Item
        to='/notice'
        title='신청 전 필독'
        img='/notice.jpg'
        num={2}
        desc='공지 안내입니다.'
      />
      <Item
        to='/practice'
        title='You를 위한 연습방♬'
        img='/practice.png'
        num={14}
        desc='잠깐!! 신청 전 여기부터 오세요!'
      />
      <Item
        to='/chat'
        title='키펙트언니오빵이들♡'
        img='/real.png'
        num={14}
        desc='신청자가 입장하였습니다.'
      />
      <Item
        to='https://story.kakao.com/kidpitcheffect'
        title='관리 계정'
        img='/kakaostory_logo.jpg'
        desc='관리계로 이동합니다.'
      />
      <Item
        to='https://namu.wiki/w/%EC%95%BC%EA%B5%AC%20%EC%9A%A9%EC%96%B4'
        title='수식어 목록'
        img='/namu.webp'
        desc='Thanks for 나무위키'
      />
    </ListWrapper>
  </Layout>
);

export default List;

const Layout = styled.main`
  height: 100vh;
  background-color: white;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const ListWrapper = styled.ul`
  margin: 0;
  list-style: none;
  padding: 48px 0 0;
`;
