import { Link } from "react-router-dom";
import { Header as HeaderContainer } from "../../style";

const Header = ({ isReal, title }) => (
  <HeaderContainer>
    <Link style={{ all: "unset" }} to='/'>{`< `}</Link>
    {title || (isReal ? "키펙트언니오빵이들♡" : "You를 위한 연습방♬")}{" "}
    <strong>{title ? 2 : 14}</strong>
  </HeaderContainer>
);

export default Header;
