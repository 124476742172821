import GlobalStyle, { Layout } from "./style";
import { RouterProvider } from "react-router";
import routes from "./routes";

const App = () => (
  <>
    <GlobalStyle />
    <Layout>
      <RouterProvider router={routes} />
    </Layout>
  </>
);

export default App;
