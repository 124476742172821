import { useEffect, useState, useRef } from "react";
import { QUESTIONS, LEVEL_MAP } from "../constants/common";

async function postData(data = {}) {
  const response = await fetch(
    "https://65cd9221c715428e8b3e96aa.mockapi.io/register",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body의 데이터 유형은 반드시 "Content-Type" 헤더와 일치해야 함
    }
  );
  return response.json(); // JSON 응답을 네이티브 JavaScript 객체로 파싱
}

export default function useChatEffect(isReal = false) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [chatLevel, setChatLevel] = useState(0);
  const [msgLevel, setMsgLevel] = useState(0);
  const [answer, setAnswer] = useState({});
  const [msg, setMsg] = useState([]);
  const mainRef = useRef();
  const section = msg.length;

  useEffect(() => {
    const chatTimer = setInterval(() => {
      if (QUESTIONS[section] && QUESTIONS[section].length > chatLevel) {
        if (QUESTIONS[section][chatLevel].msg.length <= msgLevel) {
          setMsgLevel(0);
          setChatLevel((prev) => {
            if (QUESTIONS[section].length - 1 === chatLevel) {
              setIsDisabled(false);
            }
            return prev + 1;
          });
        }
      }
    }, 500);

    const msgTimer = setInterval(() => {
      if (QUESTIONS[section] && QUESTIONS[section].length > chatLevel) {
        if (QUESTIONS[section][chatLevel].msg.length > msgLevel) {
          setMsgLevel((prev) => {
            return prev + 1;
          });
        }
      }
    }, 500);

    return () => {
      clearInterval(chatTimer);
      clearInterval(msgTimer);
    };
  }, [isDisabled, chatLevel, section, msgLevel]);

  useEffect(() => {
    const article = document.getElementsByTagName("article")[0];
    article.scrollTo({
      top: mainRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [isDisabled, msgLevel, chatLevel, section]);

  const handleAnswer = async (v) => {
    if (LEVEL_MAP[section] !== null) {
      setAnswer((prev) => ({ ...prev, [`${LEVEL_MAP[section]}`]: v }));
    }
    setMsg((prev) => [...prev, v]);
    setChatLevel(0);
    setMsgLevel(0);
    setIsDisabled(true);

    if (QUESTIONS.length - 1 === section && isReal) {
      postData(answer).then((data) => {
        console.log(data);
      });
    }
  };

  return {
    isDisabled,
    setIsDisabled,
    section,
    chatLevel,
    msgLevel,
    handleAnswer,
    msg,
    mainRef,
  };
}
