import styled from "styled-components";
import Header from "./Header";
import Notice from "./Notice";
import Keyboard from "./Keyboard";
import ChatBox from "./ChatBox";
import { QUESTIONS } from "../../constants/common";
import useChatEffect from "../../hooks/useChatEffect";

const levels = Array.from({ length: QUESTIONS.length });

const Chat = ({ isReal = false }) => {
  const {
    isDisabled,
    section,
    chatLevel,
    msgLevel,
    handleAnswer,
    msg,
    mainRef,
  } = useChatEffect(isReal);

  return (
    <Layout ref={mainRef}>
      <Header isReal={isReal} />
      <Notice text='신청자가 입장했습니다.' />
      <Notice text='방을 나가면 데이터가 저장되지 않습니다.' />
      {isReal && (
        <Notice text='신청 완료 메시지 표시 전에는 신청이 접수되지 않습니다.' />
      )}
      {isReal || <Notice text='실제 신청이 접수되는 방이 아닙니다.' />}
      {isReal || <Notice text='편하게 연습하세요!' />}
      {levels.map((_, idx) =>
        idx <= section ? (
          <>
            {idx === QUESTIONS.length - 1 &&
              (isReal ? (
                <Notice text='신청이 접수되었습니다.' />
              ) : (
                <Notice text='연습을 마칩니다.' />
              ))}
            {QUESTIONS[idx].map((item, subIdx) => (
              <ChatBox
                key={idx + "-" + subIdx}
                idx={idx}
                subIdx={subIdx}
                section={section}
                chatLevel={chatLevel}
                msgLevel={msgLevel}
                {...item}
              />
            ))}
            {(idx > 0 && !isReal) ||
              (idx === 0 && !isDisabled && (
                <>
                  <Notice text='답장은 한 번만 보낼 수 있습니다.' />
                  <Notice text='메시지를 나눠서 보낼 수 없으니 주의하세요.' />
                </>
              ))}
            {msg[idx] && (
              <ChatBox
                key={idx + "- mine"}
                msg={msg[idx]}
                section={section}
                idx={idx}
              />
            )}
          </>
        ) : null
      )}
      <Keyboard isDisabled={isDisabled} onSubmit={handleAnswer} />
    </Layout>
  );
};

export default Chat;

const Layout = styled.main`
  padding-top: 72px;
  padding-bottom: 60px;
`;
